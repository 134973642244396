import React from "react";
import ToolCallItem from "./ToolCallItem";

const FlowDataList = ({ toolCallsData, flowId }) => {
  return (
    <div className="max-w-4xl mx-auto">
      {toolCallsData.map((toolCallData, index) => {
        return (
          <div
            key={index}
            className="grid grid-cols-4 mb-4 pb-4 bg-gray-200 dark:bg-gray-600 dark:text-gray-300 bg-opacity-50 rounded-lg"
          >
            <ToolCallItem flowId={flowId} toolCallData={toolCallData} />
          </div>
        );
      })}
    </div>
  );
};

export default FlowDataList;
