import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import FlowDataList from "./FlowDataList";
import FlowMetadataItem from "./FlowMetadataItem";
import FlowModeItem from "./FlowModeItem";

const FlowDetails = () => {
  const { flowId } = useParams();
  const [metadata, setMetadata] = useState(null);
  const [toolCallsData, setToolCallsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const fetchFlowDetails = async (id) => {
    try {
      setMetadata((await axios.get(`/api/flows/${id}`)).data);
      setToolCallsData((await axios.get(`/api/flows/${id}/tool-calls`)).data);
      setLoading(false);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError("No flow found with the ID: " + id);
      } else {
        setError("Failed to fetch flow metadata: " + err);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFlowDetails(flowId);
    const intervalId = setInterval(() => {
      fetchFlowDetails(flowId);
    }, 1000); // Poll every second

    return () => clearInterval(intervalId);
  }, [flowId]);

  if (loading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>{error}</div>;
  } else {
    return (
      <div className="py-4 min-h-screen">
        <FlowMetadataItem metadata={metadata} toolCallsData={toolCallsData} />
        {toolCallsData.length > 0 && (
          <FlowDataList toolCallsData={toolCallsData} flowId={flowId} />
        )}
        <FlowModeItem metadata={metadata} />
      </div>
    );
  }
};

export default FlowDetails;
