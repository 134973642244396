import React, { useState, useEffect } from "react";
import axios from "axios";

const ApiKeyModal = ({ onClose }) => {
  const [openAiKey, setOpenAiKey] = useState("");
  const [anthropicKey, setAnthropicKey] = useState("");
  const [googleKey, setGoogleKey] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSaveKey = async (platform, key) => {
    try {
      const response = await axios.post(`/api/api-keys/${platform}`, {
        key: key,
      });
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      console.error(`Error setting ${platform} key:`, error);
      setErrorMessage(`Failed to set ${platform} key.`);
    }
    return false;
  };

  const handleSaveKeys = async () => {
    const openAiSaved = await handleSaveKey("openai", openAiKey);
    const anthropicSaved = await handleSaveKey("anthropic", anthropicKey);
    const googleSaved = await handleSaveKey("google", googleKey);

    if (openAiSaved || anthropicSaved || googleSaved) {
      onClose();
    } else {
      setErrorMessage("Please provide at least one valid API key.");
    }
  };

  useEffect(() => {
    const fetchKeysStatus = async () => {
      let openAiKeyFound = false;
      let anthropicKeyFound = false;
      let googleKeyFound = false;

      try {
        const openAiResponse = await axios.get("/api/api-keys/openai");
        if (openAiResponse.status === 200) {
          openAiKeyFound = true;
        }
      } catch (error) {
        console.log("OpenAI key not found");
      }

      try {
        const anthropicResponse = await axios.get("/api/api-keys/anthropic");
        if (anthropicResponse.status === 200) {
          anthropicKeyFound = true;
        }
      } catch (error) {
        console.log("Anthropic key not found");
      }

      try {
        const googleResponse = await axios.get("/api/api-keys/google");
        if (googleResponse.status === 200) {
          googleKeyFound = true;
        }
      } catch (error) {
        console.log("Google key not found");
      }

      if (!openAiKeyFound && !anthropicKeyFound && !googleKeyFound) {
        setErrorMessage(
          "Please provide API keys for OpenAI, Anthropic, or Google.",
        );
      } else {
        onClose();
      }
    };

    fetchKeysStatus();
  }, [onClose]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-300 mb-6">
          API Key Management
        </h2>
        <div className="space-y-4">
          <div>
            <label
              htmlFor="openAiKey"
              className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
            >
              OpenAI API Key
            </label>
            <input
              type="password"
              id="openAiKey"
              name="openAiKey"
              value={openAiKey}
              onChange={(e) => setOpenAiKey(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div>
            <label
              htmlFor="anthropicKey"
              className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
            >
              Anthropic API Key
            </label>
            <input
              type="password"
              id="anthropicKey"
              name="anthropicKey"
              value={anthropicKey}
              onChange={(e) => setAnthropicKey(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div>
            <label
              htmlFor="googleKey"
              className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
            >
              Google API Key
            </label>
            <input
              type="password"
              id="googleKey"
              name="googleKey"
              value={googleKey}
              onChange={(e) => setGoogleKey(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>
        {errorMessage && (
          <div className="text-red-500 text-sm mt-2">{errorMessage}</div>
        )}
        <div className="flex items-center justify-between mt-6">
          <button
            onClick={handleSaveKeys}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApiKeyModal;
