import React, { useState } from "react";
import Modal from "./Modal";
import { formatToolName, getAdditionalToolFields, truncateLink } from "./utils";
import { getStatusBadge } from "./badgeUtils";
import TruncatedText from "./TruncatedText";

const ToolCallItem = ({ flowId, toolCallData }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const toggleExpansion = () => setIsExpanded(!isExpanded);
  const toggleImageModal = () => setIsImageModalOpen(!isImageModalOpen);

  // Get additional tool fields if any
  const additionalFields = getAdditionalToolFields(toolCallData);

  return (
    <>
      <div className="col-span-3 pt-4 px-4">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
          <div className="cursor-pointer" onClick={toggleExpansion}>
            <h2 className="text-lg font-semibold">
              {formatToolName(toolCallData.toolName)}
            </h2>
            <p>{toolCallData.parameters.rationale}</p>
            <div className="flex justify-end space-x-2 mt-2">
              {toolCallData.outcome && toolCallData.outcome.isSuccessful
                ? getStatusBadge("SUCCESS", true)
                : getStatusBadge("FAILED", true)}
            </div>
          </div>
          {isExpanded && (
            <div className="bg-gray-200 dark:bg-gray-600 rounded-b-lg mt-2 p-2">
              {additionalFields.map((field, index) => (
                <div key={index}>
                  <h3 className="expanded-element-label">{field.label}</h3>
                  {field.label === "Code" ? (
                    <div className="expanded-element-description-container">
                      <pre className="bg-gray-900 text-emerald-300 p-4 rounded-md overflow-x-auto text-sm">
                        <code className="language-javascript">
                          {field.value}
                        </code>
                      </pre>
                    </div>
                  ) : (
                    <div className="expanded-element-description-container">
                      <TruncatedText
                        text={field.value}
                        maxLength={100}
                        className="expanded-element-description-text"
                      />
                    </div>
                  )}
                </div>
              ))}

              <h3 className="expanded-element-label">Link</h3>
              <div className="expanded-element-description-container">
                <p className="expanded-element-description-text">
                  {truncateLink(toolCallData.page)}
                </p>
              </div>

              <h3 className="expanded-element-label">Started at</h3>
              <div className="expanded-element-description-container">
                <p className="expanded-element-description-text">
                  {toolCallData.startedAt}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col-span-1">
        <div
          className="cursor-pointer rounded-lg pt-4 px-4"
          onClick={toggleExpansion}
        >
          {toolCallData.postCallImageId ? (
            <>
              <img
                src={`/api/flows/${flowId}/images/${toolCallData.postCallImageId}`}
                alt="Screenshot"
                className="w-full h-auto rounded-lg"
                onClick={toggleImageModal}
              />
              <Modal isOpen={isImageModalOpen} onClose={toggleImageModal}>
                <img
                  src={`/api/flows/${flowId}/images/${toolCallData.postCallImageId}`}
                  alt="Screenshot"
                  className="max-w-full max-h-screen rounded-lg"
                />
              </Modal>
            </>
          ) : (
            <div className="flex items-center justify-center w-full h-full bg-gray-100 dark:bg-gray-700 rounded-lg p-4">
              <p className="text-gray-500 dark:text-gray-300">No preview</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ToolCallItem;
