// In FlowItem.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "./Modal";
import { getDisplayText } from "./utils";
import {
  getStatusBadge,
  getBrowserIcon,
  getDeviceIcon,
  getModeBadge,
} from "./badgeUtils";
import PlayIcon from "./svgIcons/PlayIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";

const FlowItem = ({ metadata }) => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [previewImageId, setPreviewImageId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const refreshPreviewImageId = async (id) => {
    try {
      const toolCallsData = (await axios.get(`/api/flows/${id}/tool-calls`))
        .data;
      const toolCallToGetPreviewImageFrom = toolCallsData.find(
        (item) => item.toolName === "navigateToWebpage",
      );

      if (toolCallToGetPreviewImageFrom) {
        setPreviewImageId(toolCallToGetPreviewImageFrom.postCallImageId);
      } else if (0 < toolCallsData.length) {
        setPreviewImageId(toolCallsData[0].postCallImageId);
      }
    } catch (err) {
      const errorMessage =
        'Failed to fetch flow details for flow "' + id + '": ' + err;
      console.log(errorMessage);
      setError(errorMessage);
    }

    setLoading(false);
  };

  useEffect(() => {
    refreshPreviewImageId(metadata.id);
  }, [metadata.id]);

  const navigateToFlowDetails = async () => {
    navigate(`/flows/${metadata.id}`);
  };

  const toggleVideoModal = () => setIsVideoModalOpen(!isVideoModalOpen);

  return (
    <div className="mb-4 pb-4 bg-gray-200 dark:bg-gray-600 dark:text-gray-300 bg-opacity-50 rounded-lg max-w-4xl mx-auto relative">
      <div className="grid grid-cols-4">
        <div className="col-span-3 pt-4 px-4">
          {error && (
            <div className="bg-red-200 dark:bg-red-900 rounded-lg shadow p-4">
              <p>{error}</p>
            </div>
          )}
          {!error && (
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
              <div className="cursor-pointer" onClick={navigateToFlowDetails}>
                <h2 className="text-xl font-semibold">
                  {getDisplayText(metadata.targetWebsite)}
                </h2>
                <p>{metadata.overallObjective}</p>
                <div className="flex justify-between text-sm">
                  <div className="space-x-2 mt-2 text-gray-600 dark:text-gray-600">
                    {metadata.startedAt
                      ? format(
                          new Date(metadata.startedAt),
                          "MMMM d, yyyy h:mm a",
                        )
                      : ""}
                  </div>
                  <div className="space-x-2 mt-2 flex">
                    <div className="items-center -mr-1 flex tooltip">
                      {getDeviceIcon(metadata.deviceName)}
                      {getBrowserIcon(metadata.deviceName)}
                      <span className="tooltiptext">{metadata.deviceName}</span>
                    </div>
                    {getModeBadge(metadata.runMode)}
                    {metadata.status && metadata.status.code
                      ? getStatusBadge(metadata.status.code)
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {loading && previewImageId && (
          <div className="col-span-1 rounded-lg pt-4 px-4">
            <div className="cursor-pointer flex items-center justify-center relative">
              <img
                src={`/api/flows/${metadata.id}/images/${previewImageId}`}
                alt="Screenshot"
                className="w-full h-auto rounded-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVideoModal();
                }}
              />
              <div
                className="absolute inset-0 flex items-center justify-center"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVideoModal();
                }}
              >
                <FontAwesomeIcon icon={faSpinner} className="fa-spin mt-32" />
              </div>
            </div>
          </div>
        )}
        {!loading && previewImageId && (
          <div className="col-span-1 rounded-lg pt-4 px-4">
            <div className="cursor-pointer flex items-center justify-center relative">
              <img
                src={`/api/flows/${metadata.id}/images/${previewImageId}`}
                alt="Screenshot"
                className="w-full h-auto rounded-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVideoModal();
                }}
              />
              <div
                className="absolute inset-0 flex items-center justify-center"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVideoModal();
                }}
              >
                <PlayIcon />
              </div>
            </div>
          </div>
        )}
      </div>
      {isVideoModalOpen && (
        <Modal isOpen={isVideoModalOpen} onClose={toggleVideoModal}>
          <video
            controls
            autoPlay
            className="max-w-full max-h-screen rounded-lg"
          >
            <source src={`/api/flows/${metadata.id}/video`} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </Modal>
      )}
    </div>
  );
};

export default FlowItem;
