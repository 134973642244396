import React, { useState, useEffect } from "react";
import axios from "axios";

const ApiKeys = () => {
  const [apiKey, setApiKey] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const response = await axios.get("/api/api-keys/openai");
        setIsEditing(response.status !== 200);
      } catch (err) {
        setIsEditing(true);
      } finally {
        setLoading(false);
      }
    };

    fetchApiKey();
  }, []);

  const handleSubmit = async () => {
    setSubmitLoading(true);
    setError("");
    try {
      const response = await axios.post("/api/api-keys/openai", apiKey);
      if (response.status === 200) {
        setIsEditing(false);
      } else {
        setError("Invalid API key.");
      }
    } catch (err) {
      setError("Invalid API key.");
    } finally {
      setSubmitLoading(false);
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="p-4 max-w-md mx-auto">
      {!isEditing ? (
        <div className="flex items-center justify-between">
          <span>OpenAI API Key</span>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={() => setIsEditing(true)}
          >
            Edit
          </button>
        </div>
      ) : (
        <div className="space-y-4">
          <input
            type="text"
            className={`w-full p-2 border ${error ? "border-red-500" : "border-gray-300"} rounded`}
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center"
            onClick={handleSubmit}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v8H4z"
                ></path>
              </svg>
            ) : (
              "Submit"
            )}
          </button>
          {error && <div className="text-red-500">{error}</div>}
        </div>
      )}
    </div>
  );
};

export default ApiKeys;
