import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { devices, formatToolName, getAvailableModels } from "./utils";
import ApiKeyModal from "./ApiKeyModal";
import GettingStartedPromptsPanel from "./GettingStartedPromptsPanel";

const CreateFlow = () => {
  const DEFAULT_MAX_ITERATIONS = 5;

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const initialData = location.state || {};

  // State definitions with search parameters as initial values (if available)
  const [deviceName, setDeviceName] = useState(
    searchParams.get("deviceName") || initialData.deviceName || "",
  );
  const searchParamsMaxIterations = parseInt(searchParams.get("maxIterations"));
  const [maxIterations, setMaxIterations] = useState(
    searchParamsMaxIterations ||
      initialData.maxIterations ||
      DEFAULT_MAX_ITERATIONS,
  );
  const [targetWebsite, setTargetWebsite] = useState(
    searchParams.get("targetWebsite") || initialData.targetWebsite || "",
  );
  const [overallObjective, setOverallObjective] = useState(
    searchParams.get("overallObjective") || initialData.overallObjective || "",
  );
  const [contextHelpForObjective, setContextHelpForObjective] = useState(
    searchParams.get("contextHelpForObjective") ||
      initialData.contextHelpForObjective ||
      "",
  );
  const [flowStatus, setFlowStatus] = useState("");
  const [supportedDevices, setSupportedDevices] = useState([]);
  const [tools, setTools] = useState([]);
  const [allowedTools, setAllowedTools] = useState(
    searchParams.get("allowedTools")
      ? searchParams.get("allowedTools").split(",")
      : initialData.allowedTools || [],
  );
  const [isSelectToolsExpanded, setIsSelectToolsExpanded] = useState(false);
  const [gptPlatform, setGptPlatform] = useState(
    searchParams.get("gptPlatform") || "OPENAI",
  );
  const [gptModel, setGptModel] = useState(
    searchParams.get("gptModel") ||
      getAvailableModels(searchParams.get("gptPlatform") || "OPENAI")[0],
  );
  const [showApiKeyModal, setShowApiKeyModal] = useState(false);

  const isInitialAllowedToolsSet = useRef(false);
  const dropdownRef = useRef(null);

  const toggleSelectToolsDropdown = () =>
    setIsSelectToolsExpanded(!isSelectToolsExpanded);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      targetWebsite,
      overallObjective,
      contextHelpForObjective,
      deviceName,
      maxIterations: parseInt(maxIterations),
      allowedTools,
      gptPlatform,
      gptModel,
    };

    try {
      const response = await axios.post("/api/flows", data);
      const newFlowId = response.data.id;
      navigate(`/flows/${newFlowId}`);
    } catch (error) {
      setFlowStatus("Failed to create flow: " + error.message);
    }
  };

  const toggleToolSelection = (toolName) => {
    setAllowedTools((prevAllowedTools) => {
      if (prevAllowedTools.includes(toolName)) {
        // If all tools are currently selected, and a tool is clicked, only select that tool
        if (prevAllowedTools.length === tools.length) {
          return [toolName];
        } else {
          // Otherwise, remove the selected tool from the list
          return prevAllowedTools.filter((t) => t !== toolName);
        }
      } else {
        // If the tool is not in the list, add it
        return [...prevAllowedTools, toolName];
      }
    });
  };

  const handlePlatformChange = (event) => {
    const platform = event.target.value;
    setGptPlatform(platform);
    setGptModel(getAvailableModels(platform)[0]);
  };

  const handleModelChange = (event) => {
    setGptModel(event.target.value);
  };

  useEffect(() => {
    document.body.classList.add("bg-gray-100", "dark:bg-gray-900");

    return () => {
      document.body.classList.remove("bg-gray-100", "dark:bg-gray-900");
    };
  }, []);

  useEffect(() => {
    setSupportedDevices(devices);
  }, []);

  useEffect(() => {
    if (!location.state) {
      const fetchInitialValues = async () => {
        try {
          const response = await axios.get("/api/flows?limit=1");
          if (response.status === 200 && response.data.flows[0] !== undefined) {
            const data = response.data.flows[0];

            // Only set state if not already set by searchParams
            if (!deviceName) setDeviceName(data.deviceName);
            if (
              !searchParamsMaxIterations &&
              maxIterations === DEFAULT_MAX_ITERATIONS
            )
              setMaxIterations(data.maxIterations);
            if (!targetWebsite) setTargetWebsite(data.targetWebsite);
            if (!overallObjective) setOverallObjective(data.overallObjective);
            if (!contextHelpForObjective)
              setContextHelpForObjective(data.contextHelpForObjective);

            if (data.allowedTools && allowedTools.length === 0) {
              setAllowedTools(data.allowedTools);
              isInitialAllowedToolsSet.current = true;
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            console.log("No recent run params found.");
          } else {
            console.error("Error fetching initial values:", error);
          }
        }
      };

      fetchInitialValues();
    }
  }, [
    location.state,
    searchParamsMaxIterations,
    deviceName,
    maxIterations,
    targetWebsite,
    overallObjective,
    contextHelpForObjective,
    allowedTools,
  ]);

  useEffect(() => {
    const fetchTools = async () => {
      try {
        const response = await axios.get("/api/tools");
        if (response.status === 200) {
          const toolsData = response.data;
          setTools(toolsData);
        }
      } catch (error) {
        console.error("Error fetching tools:", error);
      }
    };
    fetchTools();
  }, []);

  useEffect(() => {
    if (!isInitialAllowedToolsSet.current && allowedTools.length === 0) {
      setAllowedTools(tools.map((tool) => tool.name));
    }
  }, [allowedTools, tools]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsSelectToolsExpanded(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkKeys = async () => {
      try {
        const openAiResponse = await axios.get("/api/api-keys/openai");
        const anthropicResponse = await axios.get("/api/api-keys/anthropic");
        const googleResponse = await axios.get("/api/api-keys/google");
        if (
          openAiResponse.status !== 200 &&
          anthropicResponse.status !== 200 &&
          googleResponse.status !== 200
        ) {
          setShowApiKeyModal(true);
        }
      } catch (error) {
        console.error("Error checking API keys:", error);
        setShowApiKeyModal(true);
      }
    };

    checkKeys();
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen flex overflow-auto mt-8 mb-8 dark:bg-gray-900 dark:text-gray-300">
      {showApiKeyModal && (
        <ApiKeyModal onClose={() => setShowApiKeyModal(false)} />
      )}
      <form onSubmit={handleSubmit} className="flex w-full">
        <div className="fixed inset-y-16 left-0 w-64 p-8 bg-gray-100 dark:bg-gray-900 dark:text-gray-300">
          {/* Flow Form Components can be extracted here */}
          <div className="space-y-4">
            <div>
              <label
                htmlFor="deviceName"
                className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
              >
                Device
                <span className="tooltip">
                  <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                  <span className="tooltiptext">
                    The web browsing environment to emulate when running the
                    script.
                  </span>
                </span>
              </label>
              <select
                id="deviceName"
                name="deviceName"
                value={deviceName}
                onChange={(e) => setDeviceName(e.target.value)}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="" disabled>
                  Select a device
                </option>
                {supportedDevices.map((device) => (
                  <option key={device.value} value={device.value}>
                    {device.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="maxIterations"
                className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
              >
                Max Iterations
                <span className="tooltip">
                  <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                  <span className="tooltiptext">
                    The maximum number of times to go back and forth with the
                    primary LLM. Constraining this number can help prevent
                    accidental infinite loops (and cost).
                  </span>
                </span>
              </label>
              <input
                type="number"
                id="maxIterations"
                name="maxIterations"
                value={maxIterations}
                onChange={(e) => setMaxIterations(e.target.value)}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div>
              <label
                htmlFor="gptPlatform"
                className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
              >
                Platform
              </label>
              <select
                id="gptPlatform"
                name="gptPlatform"
                value={gptPlatform}
                onChange={handlePlatformChange}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="OPENAI">OpenAI</option>
                <option value="ANTHROPIC">Anthropic</option>
                <option value="GOOGLE">Google</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="gptModel"
                className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
              >
                Model
              </label>
              <select
                id="gptModel"
                name="gptModel"
                value={gptModel}
                onChange={handleModelChange}
                required
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                {getAvailableModels(gptPlatform).map((model) => (
                  <option key={model} value={model}>
                    {model}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="flex-1 ml-64">
          <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-lg">
            <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-300 mb-6">
              Donobu
            </h2>
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="targetWebsite"
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                >
                  Website URL
                </label>
                <input
                  type="url"
                  id="targetWebsite"
                  name="targetWebsite"
                  value={targetWebsite}
                  onChange={(e) => setTargetWebsite(e.target.value)}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="https://www.example.com"
                />
              </div>
              <div>
                <label
                  htmlFor="overallObjective"
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                >
                  Objective
                  <span className="tooltip">
                    <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                    <span className="tooltiptext">
                      The overall objective to pursue. The script will stop once
                      this is achieved.
                    </span>
                  </span>
                </label>
                <textarea
                  id="overallObjective"
                  name="overallObjective"
                  value={overallObjective}
                  onChange={(e) => setOverallObjective(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Help me sign up for this website."
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="contextHelpForObjective"
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                >
                  Prompt
                  <span className="tooltip">
                    <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                    <span className="tooltiptext dark:bg-gray-700 dark:text-gray-300">
                      Context that may be useful for pursuing the overall
                      objective. This may contain specific instructions like to
                      use a specific email pattern, username, etc.
                    </span>
                  </span>
                </label>
                <textarea
                  id="contextHelpForObjective"
                  name="contextHelpForObjective"
                  value={contextHelpForObjective}
                  onChange={(e) => setContextHelpForObjective(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Use the name John Garlane.
Use a random email like jgarlane@example.com.
...other necessary details..."
                  rows="7"
                />
              </div>
              <div>
                <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                  Tools
                  <span className="tooltip">
                    <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
                    <span className="tooltiptext">
                      The list of tools that can be used by Autonomous mode to
                      complete the task. By default all tools are available.
                    </span>
                  </span>
                </label>
                <div className="bg-gray-100 dark:bg-gray-900">
                  <div className="flex text-xs px-2.5 py-2 items-center mx-auto">
                    <h3 className="text-gray-600 font-medium text-sm leading-5 dark:text-gray-400">
                      Selected
                    </h3>
                    <div className="sm:block sm:ml-4 bg-gray-300 dark:bg-gray-700 w-0.5 h-5"></div>
                    <div className="flex flex-wrap m:mt-0 sm:ml-4 items-center">
                      {allowedTools.length === tools.length ? (
                        <span className="items-center text-gray-900 dark:text-gray-200 font-medium text-xs leading-5 mr- my-0.5 px-2.5 py-0.5 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-full inline-flex ">
                          All
                        </span>
                      ) : (
                        allowedTools.map((tool) => (
                          <span
                            key={tool}
                            className="items-center text-gray-900 dark:text-gray-200 font-medium text-xs leading-5 mr-2 my-0.5 px-2.5 py-0.5 bg-white dark:bg-gray-900 border border-gray-300 dark:border-gray-700 rounded-full inline-flex cursor-pointer"
                            onClick={() => toggleToolSelection(tool)}
                          >
                            {formatToolName(tool)}
                            <button
                              type="button"
                              className="text-gray-400 dark:text-gray-600 p-1 rounded-full flex-shrink-0 w-4 h-4 inline-flex ml-1 items-center"
                            >
                              <svg
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 8 8"
                                className="w-2 h-2"
                              >
                                <path
                                  d="M1 1l6 6m0-6L1 7"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                ></path>
                              </svg>
                            </button>
                          </span>
                        ))
                      )}
                    </div>
                  </div>
                </div>

                <div
                  className="relative inline-block text-left mt-2"
                  ref={dropdownRef}
                >
                  <button
                    type="button"
                    className="inline-flex justify-center items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
                    id="tools-menu"
                    onClick={() => toggleSelectToolsDropdown()}
                  >
                    Select Tools
                    {isSelectToolsExpanded ? (
                      <FontAwesomeIcon
                        icon={faChevronUp}
                        className="-mr-1 ml-2 h-3 w-3"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="-mr-1 ml-2 h-3 w-3"
                      />
                    )}
                  </button>
                  {isSelectToolsExpanded && (
                    <div
                      className="z-10 origin-top-right absolute mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 dark:bg-gray-700 focus:outline-none"
                      id="toolListDropdown"
                    >
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="tools-menu"
                      >
                        <button
                          type="button"
                          onClick={() =>
                            setAllowedTools(tools.map((tool) => tool.name))()
                          }
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 w-full text-left"
                          role="menuitem"
                        >
                          All Tools
                        </button>
                        <div className="border-t border-gray-200 dark:border-gray-600"></div>
                        {/* if All tools are selected, show dropdown with all tools, else show only show dropdown for tools that aren't selected yet */}
                        {(allowedTools.length === tools.length
                          ? tools
                          : tools.filter(
                              (tool) => !allowedTools.includes(tool.name),
                            )
                        ).map((tool) => (
                          <button
                            key={tool.name}
                            type="button"
                            onClick={() => toggleToolSelection(tool.name)}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 w-full text-left"
                            role="menuitem"
                          >
                            {formatToolName(tool.name)}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between mt-6">
                <button
                  type="submit"
                  className="z-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline dark:bg-blue-400 dark:hover:bg-blue-600"
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="status text-sm text-gray-700 dark:text-gray-300 mt-2">
              {flowStatus}
            </div>
          </div>
        </div>

        {/* Getting Started Prompts Panel */}
        <div className="flex-1 mx-8">
          <GettingStartedPromptsPanel />
        </div>
      </form>
    </div>
  );
};

export default CreateFlow;
