import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ApiKeys from "./ApiKeys";
import CreateFlow from "./CreateFlow";
import CreateBlueArcFlow from "./CreateBlueArcFlow";
// import Heartbeat from './Heartbeat';
import FlowsList from "./FlowsList";
import FlowDetails from "./FlowDetails";
import NavBar from "./NavBar";

function App() {
  return (
    <Router>
      <NavBar />
      <div className="bg-gray-100 dark:bg-gray-900 pt-16">
        {" "}
        {/* Add padding-top to prevent overlap */}
        {/* <Heartbeat />  */}
        <Routes>
          <Route path="/api-keys" element={<ApiKeys />} />
          <Route path="/" element={<CreateFlow />} />
          <Route path="/blue-arc" element={<CreateBlueArcFlow />} />
          <Route path="/flows" element={<FlowsList />} />
          <Route path="/flows/:flowId" element={<FlowDetails />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
