// src/badgeUtils.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faMobileAlt,
  faCheckCircle,
  faTimesCircle,
  faClock,
  faRepeat,
  faTabletAlt,
} from "@fortawesome/free-solid-svg-icons";

import ChromeIcon from "./svgIcons/ChromeIcon";
import FirefoxIcon from "./svgIcons/FirefoxIcon";
import SafariIcon from "./svgIcons/SafariIcon";
import EdgeIcon from "./svgIcons/EdgeIcon";
import SparklesIcon from "./svgIcons/SparklesIcon";

export const getStatusBadge = (status, iconOnly = false) => {
  let style =
    "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium";
  if (iconOnly) {
    style = style + " tooltip";
  }
  switch (status) {
    case "SUCCESS":
      return (
        <span className={style + " bg-green-100 text-green-800"}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className={!iconOnly ? "mr-1" : ""}
          />{" "}
          {!iconOnly ? status : ""}
          {iconOnly && <span className="tooltiptext">{status}</span>}
        </span>
      );
    case "FAILED":
      return (
        <span className={style + " bg-red-100 text-red-800"}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className={!iconOnly ? "mr-1" : ""}
          />{" "}
          {!iconOnly ? status : ""}
          {iconOnly && <span className="tooltiptext">{status}</span>}
        </span>
      );
    default:
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
          <FontAwesomeIcon icon={faClock} className={!iconOnly ? "mr-1" : ""} />{" "}
          {!iconOnly ? status : ""}
        </span>
      );
  }
};

export const getDeviceIcon = (device) => {
  if (!device) return null;
  device = device.toLowerCase();
  if (device.includes("desktop")) {
    return <FontAwesomeIcon icon={faDesktop} className="mr-1" />;
  }
  if (
    device.includes("mobile") ||
    device.includes("iphone") ||
    device.includes("ios") ||
    device.includes("nexus") ||
    device.includes("pixel") ||
    device.includes("lg") ||
    device.includes("kindle")
  ) {
    return <FontAwesomeIcon icon={faMobileAlt} className="mr-1" />;
  }
  if (
    device.includes("tablet") ||
    device.includes("ipad") ||
    device.includes("galaxy tab")
  ) {
    return <FontAwesomeIcon icon={faTabletAlt} className="mr-1" />;
  }
  // Default icon
  return <FontAwesomeIcon icon={faMobileAlt} className="mr-1" />;
};

export const getBrowserIcon = (browser) => {
  if (!browser) return null;
  browser = browser.toLowerCase();
  if (browser.includes("chrome")) {
    return <ChromeIcon className="mr-1 w-4 h-4 inline" />;
  }
  if (browser.includes("firefox")) {
    return <FirefoxIcon className="mr-1 w-4 h-4 inline" />;
  }
  if (browser.includes("safari")) {
    return <SafariIcon className="mr-1 w-4 h-4 inline" />;
  }
  if (browser.includes("edge")) {
    return <EdgeIcon className="mr-1 w-4 h-4 inline" />;
  }
  // Default icon
  return <></>;
};

export const getModeBadge = (mode) => {
  switch (mode) {
    case "AUTONOMOUS":
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 tooltip">
          <SparklesIcon className="mr-1 w-4 h-4 inline" /> {mode}
          <span className="tooltiptext">Autonomous Mode</span>
        </span>
      );
    case "ON_RAILS": // For 'ON_RAILS' display 'REPLAY'
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 tooltip">
          <FontAwesomeIcon icon={faRepeat} className="mr-1" /> REPLAY
          <span className="tooltiptext">Replay Mode</span>
        </span>
      );
    default:
      return (
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
          {mode}
        </span>
      );
  }
};
