import React from "react";

const SafariIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path
      fill="currentColor"
      d="M274.7 274.7l-37.4-37.4L166 346zM256 8C119 8 8 119 8 256S119 504 256 504 504 393 504 256 393 8 256 8zM411.9 182.8l14.8-6.1A8 8 0 0 1 437.1 181h0a8 8 0 0 1 -4.3 10.5L418 197.6a8 8 0 0 1 -10.5-4.3h0A8 8 0 0 1 411.9 182.8zM314.4 94l6.1-14.8A8 8 0 0 1 331 74.9h0a8 8 0 0 1 4.3 10.5l-6.1 14.8a8 8 0 0 1 -10.5 4.3h0A8 8 0 0 1 314.4 94zM256 60h0a8 8 0 0 1 8 8V84a8 8 0 0 1 -8 8h0a8 8 0 0 1 -8-8V68A8 8 0 0 1 256 60zM181 74.9a8 8 0 0 1 10.5 4.3L197.6 94a8 8 0 1 1 -14.8 6.1l-6.1-14.8A8 8 0 0 1 181 74.9zm-63.6 42.5h0a8 8 0 0 1 11.3 0L140 128.7A8 8 0 0 1 140 140h0a8 8 0 0 1 -11.3 0l-11.3-11.3A8 8 0 0 1 117.4 117.4zM60 256h0a8 8 0 0 1 8-8H84a8 8 0 0 1 8 8h0a8 8 0 0 1 -8 8H68A8 8 0 0 1 60 256zm40.2 73.2-14.8 6.1A8 8 0 0 1 74.9 331h0a8 8 0 0 1 4.3-10.5L94 314.4a8 8 0 0 1 10.5 4.3h0A8 8 0 0 1 100.2 329.2zm4.3-136h0A8 8 0 0 1 94 197.6l-14.8-6.1A8 8 0 0 1 74.9 181h0a8 8 0 0 1 10.5-4.3l14.8 6.1A8 8 0 0 1 104.5 193.2zM197.6 418l-6.1 14.8a8 8 0 0 1 -14.8-6.1l6.1-14.8A8 8 0 1 1 197.6 418zM264 444a8 8 0 0 1 -8 8h0a8 8 0 0 1 -8-8V428a8 8 0 0 1 8-8h0a8 8 0 0 1 8 8zm67-6.9h0a8 8 0 0 1 -10.5-4.3L314.4 418a8 8 0 0 1 4.3-10.5h0a8 8 0 0 1 10.5 4.3l6.1 14.8A8 8 0 0 1 331 437.1zm63.6-42.5h0a8 8 0 0 1 -11.3 0L372 383.3A8 8 0 0 1 372 372h0a8 8 0 0 1 11.3 0l11.3 11.3A8 8 0 0 1 394.6 394.6zM286.3 286.3 110.3 401.7 225.8 225.8 401.7 110.3zM437.1 331h0a8 8 0 0 1 -10.5 4.3l-14.8-6.1a8 8 0 0 1 -4.3-10.5h0A8 8 0 0 1 418 314.4l14.8 6.1A8 8 0 0 1 437.1 331zM444 264H428a8 8 0 0 1 -8-8h0a8 8 0 0 1 8-8h16a8 8 0 0 1 8 8h0A8 8 0 0 1 444 264z"
    />
  </svg>
);

export default SafariIcon;
