import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faChartLine,
  faSignIn,
} from "@fortawesome/free-solid-svg-icons";

const prompts = [
  {
    label: "What is this website about?",
    url: "/?targetWebsite=https%3A%2F%2Fwww.canva.com%2F&overallObjective=What%20is%20this%20website%20about%3F%20When%20confident%20about%20the%20overall%20subject%20of%20the%20website%2C%20summarize%20the%20learnings%20and%20end%20the%20flow.&contextHelpForObjective=If%20they%20have%20an%20Instagram%20page%2C%20check%20it%20out",
    icon: faEye, // Icon for this prompt
  },
  {
    label: "Increase my Hackernews karma with comments",
    url: "/?targetWebsite=https%3A%2F%2Fnews.ycombinator.com%2F&overallObjective=Increase%20my%20Hackernews%20karma%20with%20comments&contextHelpForObjective=Go%20to%20comments%20for%20a%20thread%20that%27s%20seeing%20many%20comments%20on%20it.%20Ask%20an%20insightful%20question%20or%20make%20an%20insightful%20comment%20on%20the%20top%20comment%20within%20that%20news%20thread.%5CnIf%20login%20form%20is%20shown%2C%20pause%20for%20user%20to%20enter%20login%20details.%5CnObjective%20is%20complete%20when%20the%20comment%20has%20been%20posted.&maxIterations=10",
    icon: faChartLine, // Icon for this prompt
  },
  {
    label: "Create an account on this website",
    url: "/?targetWebsite=https%3A%2F%2Fstage.everbutton.com&overallObjective=Create%20an%20account%20on%20this%20website&contextHelpForObjective=Use%20a%20random%20email%20variation%20of%20myemail%40example.com%0AMake%20up%20reasonable%20data%20including%20passwords%20to%20fill%20all%20the%20forms&maxIterations=8",
    icon: faSignIn, // Icon for this prompt
  },
  // Add more prompts as needed
];

const GettingStartedPromptsPanel = () => {
  const handlePromptClick = (url) => {
    window.location.href = url; // Forces a page reload with the new URL
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-lg">
      <h3 className="text-xl font-bold text-gray-800 dark:text-gray-300 mb-4">
        Getting Started Prompts
      </h3>
      <div className="space-y-4">
        {prompts.map((prompt, index) => (
          <Link
            key={index}
            to={prompt.url}
            onClick={() => handlePromptClick(prompt.url)}
            className="relative flex w-40 flex-col gap-2 rounded-2xl border border-token-border-light px-3 pb-4 pt-3 text-start align-top text-[15px] shadow-xxs transition hover:bg-gray-100 dark:hover:bg-gray-700"
          >
            <FontAwesomeIcon
              icon={prompt.icon}
              className="text-gray-400"
              size="sm"
            />
            <div className="text-center text-gray-800 dark:text-gray-300 break-word">
              {prompt.label}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default GettingStartedPromptsPanel;
