// src/utils.js

/**
 * List of device strings supported
 */
export const devices = [
  "Desktop Firefox",
  "Desktop Edge",
  "Desktop Chrome",
  "Desktop Chromium",
  "Desktop Safari",
  "Desktop Firefox HiDPI",
  "Desktop Edge HiDPI",
  "Moto G4 landscape",
  "Moto G4",
  "Pixel 7 landscape",
  "Pixel 7",
  "Pixel 5 landscape",
  "Pixel 5",
  "Pixel 4a (5G) landscape",
  "Pixel 4a (5G)",
  "Pixel 4 landscape",
  "Pixel 4",
  "Pixel 3 landscape",
  "Pixel 3",
  "Pixel 2 XL landscape",
  "Pixel 2 XL",
  "Pixel 2 landscape",
  "Pixel 2",
  "Nokia N9 landscape",
  "Nokia N9",
  "Nokia Lumia 520 landscape",
  "Nokia Lumia 520",
  "Nexus 7 landscape",
  "Nexus 7",
  "Nexus 6P landscape",
  "Nexus 6P",
  "Nexus 6 landscape",
  "Nexus 6",
  "Nexus 5X landscape",
  "Nexus 5X",
  "Nexus 5 landscape",
  "Nexus 5",
  "Nexus 4 landscape",
  "Nexus 4",
  "Nexus 10 landscape",
  "Nexus 10",
  "LG Optimus L70 landscape",
  "LG Optimus L70",
  "Kindle Fire HDX landscape",
  "Kindle Fire HDX",
  "iPhone 14 Pro Max landscape",
  "iPhone 14 Pro Max",
  "iPhone 14 Pro landscape",
  "iPhone 14 Pro",
  "iPhone 14 Plus landscape",
  "iPhone 14 Plus",
  "iPhone 14 landscape",
  "iPhone 14",
  "iPhone 13 Mini landscape",
  "iPhone 13 Mini",
  "iPhone 13 Pro Max landscape",
  "iPhone 13 Pro Max",
  "iPhone 13 Pro landscape",
  "iPhone 13 Pro",
  "iPhone 13 landscape",
  "iPhone 13",
  "iPhone 12 Mini landscape",
  "iPhone 12 Mini",
  "iPhone 12 Pro Max landscape",
  "iPhone 12 Pro Max",
  "iPhone 12 Pro landscape",
  "iPhone 12 Pro",
  "iPhone 12 landscape",
  "iPhone 12",
  "iPhone 11 Pro Max landscape",
  "iPhone 11 Pro Max",
  "iPhone 11 Pro landscape",
  "iPhone 11 Pro",
  "iPhone 11 landscape",
  "iPhone 11",
  "iPhone XR landscape",
  "iPhone XR",
  "iPhone X landscape",
  "iPhone X",
  "iPhone SE landscape",
  "iPhone SE",
  "iPhone 8 Plus landscape",
  "iPhone 8 Plus",
  "iPhone 8 landscape",
  "iPhone 8",
  "iPhone 7 Plus landscape",
  "iPhone 7 Plus",
  "iPhone 7 landscape",
  "iPhone 7",
  "iPhone 6 Plus landscape",
  "iPhone 6 Plus",
  "iPhone 6 landscape",
  "iPhone 6",
  "iPad Pro 11 landscape",
  "iPad Pro 11",
  "iPad Mini landscape",
  "iPad Mini",
  "iPad (gen 7) landscape",
  "iPad (gen 7)",
  "iPad (gen 6) landscape",
  "iPad (gen 6)",
  "iPad (gen 5) landscape",
  "iPad (gen 5)",
  "Galaxy Tab S4 landscape",
  "Galaxy Tab S4",
  "Galaxy S9+ landscape",
  "Galaxy S9+",
  "Galaxy S8 landscape",
  "Galaxy S8",
].map((device) => ({ name: device, value: device }));

/**
 * Function to determine display text based on the URL or file path.
 * @param {string} url - The URL or file path to parse.
 * @returns {string} - The display text.
 */
export const getDisplayText = (url) => {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.protocol === "file:"
      ? parsedUrl.pathname.split("/").pop()
      : parsedUrl.hostname;
  } catch (error) {
    console.error("Error parsing URL:", url);
    return url; // Return the whole url or a default text if URL is invalid
  }
};

/**
 * Function to format a camelCase string to Title Case with spaces.
 * @param {string} str - The camelCase string to format.
 * @returns {string} - The formatted string.
 */
export const formatToolName = (str) => {
  switch (str) {
    case "clickButton":
      return "Click Button";
    case "makeComment":
      return "Make Comment";
    case "runInlineJavaScriptCode":
      return "Run Inline JavaScript Code";
    case "runSandboxedJavaScriptCode":
      return "Run Sandboxed JavaScript Code";
    // Add more cases here as needed
    default:
      return str
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2")
        .replace(/^./, (match) => match.toUpperCase());
  }
};

/**
 * Get additional fields for a specific tool type.
 * @param {object} toolData - The data object for the tool.
 * @returns {Array} - An array of objects with additional fields.
 */
export const getAdditionalToolFields = (toolData) => {
  const { toolName, parameters, outcome } = toolData;
  const fields = [];

  switch (toolName) {
    case "analyzePageText":
      fields.push({
        label: "Analysis to Run",
        value: parameters.analysisToRun,
      });
      break;
    case "changeWebBrowserTab":
      fields.push({ label: "Tab URL", value: parameters.tabUrl });
      break;
    case "chooseSelectOption":
      fields.push({ label: "Option Values", value: parameters.optionValues });
      fields.push({ label: "Annotation", value: parameters.annotation });
      fields.push({
        label: "Why This Annotation",
        value: parameters.whyThisAnnotation,
      });
      break;
    case "clickButton":
      fields.push({ label: "Annotation", value: parameters.annotation });
      fields.push({
        label: "Why This Annotation",
        value: parameters.whyThisAnnotation,
      });
      break;
    case "confirmEmail":
      // No additional fields
      break;
    case "extractBusinessData":
      fields.push({ label: "Code", value: outcome.forLlm });
      break;
    case "sourceFacebookTransparencyData":
      if (outcome.metadata) {
        fields.push({
          label: "Code",
          value: JSON.stringify(outcome.metadata, null, 2),
        });
      }
      break;
    case "goToGoogleMapsStreetView":
      fields.push({ label: "Business Name", value: parameters.businessName });
      fields.push({
        label: "Business Location",
        value: parameters.businessLocation,
      });
      break;
    case "hoverOverElement":
      fields.push({ label: "Annotation", value: parameters.annotation });
      fields.push({
        label: "Why This Annotation",
        value: parameters.whyThisAnnotation,
      });
      break;
    case "inputRandomizedEmailAddress":
      fields.push({ label: "Base Email", value: parameters.baseEmail });
      fields.push({ label: "Annotation", value: parameters.annotation });
      fields.push({
        label: "Why This Annotation",
        value: parameters.whyThisAnnotation,
      });
      break;
    case "inputText":
      fields.push({ label: "Text", value: parameters.text });
      fields.push({ label: "Annotation", value: parameters.annotation });
      fields.push({
        label: "Why This Annotation",
        value: parameters.whyThisAnnotation,
      });
      break;
    case "makeComment":
      fields.push({ label: "Comment", value: parameters.comment });
      break;
    case "navigateWithinStreetView":
      fields.push({ label: "Action", value: parameters.action });
      break;
    case "objectiveComplete":
      fields.push({ label: "Details", value: parameters.details });
      break;
    case "postResultsToBlueArc":
      if (outcome.metadata) {
        fields.push({
          label: "Code",
          value: JSON.stringify(outcome.metadata.results, null, 2),
        });
      }
      break;
    case "runInlineJavaScriptCode":
    case "runSandboxedJavaScriptCode":
      fields.push({ label: "Code", value: parameters.javaScriptCode });
      break;
    case "summarizeLearnings":
      fields.push({ label: "Summary", value: parameters.summary });
      break;
    // Add more cases here as needed
    default:
      break;
  }

  return fields;
};

/**
 * Function to get list of LLM models available to Donobu for a provider.
 * @param {string} platform - The LLM provider (OpenAI, Anthropic, etc.)
 * @returns {Array} - list of GPT models available for the provider, first element is the default/preferred model.
 */
export const getAvailableModels = (platform = "OPENAI") => {
  const models = {
    OPENAI: ["gpt-4o-2024-08-06", "gpt-4o-mini"],
    ANTHROPIC: [
      "claude-3-5-sonnet-20240620",
      "claude-3-opus-20240229",
      "claude-3-haiku-20240307",
    ],
    GOOGLE: ["gemini-1.5-pro"],
  };
  return models[platform] || [];
};

/**
 * Function to truncate a URL to a specified length and append "..." with a clickable link to view the full URL.
 * @param {string} url - The URL to truncate.
 * @param {number} maxLength - The maximum length of the truncated URL.
 * @returns {JSX.Element} - The truncated URL with a clickable link.
 */
export const truncateLink = (url, maxLength = 50) => {
  let truncated = url;

  if (url.length >= maxLength) {
    truncated = url.slice(0, maxLength) + "...";
  }

  return (
    <span>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="hover:underline"
      >
        {truncated}
      </a>
    </span>
  );
};

/**
 * Function to estimate the cost of tokens used.
 * @param {number} inputTokens - Number of input tokens used.
 * @param {number} outputTokens - Number of output tokens used.
 * @param {string} model - The model used (default is 'gpt-4o').
 * @returns {number} - The estimated cost.
 */
export const estimateTokensCost = (
  inputTokens = 0,
  outputTokens = 0,
  model = "gpt-4o",
) => {
  const costPerMillionTokens = {
    "gpt-4o": {
      input: 5.0 / 1000000, // $5.00 per 1M input tokens
      output: 15.0 / 1000000, // $15.00 per 1M output tokens
    },
    "gpt-4o-2024-08-06": {
      input: 2.5 / 1000000, // $2.50 per 1M input tokens
      output: 10.0 / 1000000, // $10.00 per 1M output tokens
    },
    "gpt-4o-mini": {
      input: 0.15 / 1000000, // $0.15 per 1M input tokens
      output: 0.6 / 1000000, // $0.60 per 1M output tokens
    },
    "gpt-4-turbo": {
      input: 10.0 / 1000000, // $10 per 1M input tokens
      output: 30.0 / 1000000, // $30 per 1M output tokens
    },
    "gpt-3.5-turbo-0125": {
      input: 0.5 / 1000000, // $0.50 per 1M input tokens
      output: 1.5 / 1000000, // $1.50 per 1M output tokens
    },
    "claude-3-5-sonnet-20240620": {
      input: 3.0 / 1000000, // $3 per 1M input tokens
      output: 15.0 / 1000000, // $15 per 1M output tokens
    },
    "claude-3-opus-20240229": {
      input: 15.0 / 1000000, // $15 per 1M input tokens
      output: 75.0 / 1000000, // $75 per 1M output tokens
    },
    "claude-3-haiku-20240307": {
      input: 0.25 / 1000000, // $0.25 per 1M input tokens
      output: 1.25 / 1000000, // $1.25 per 1M output tokens
    },
    "gemini-1.5-pro": {
      input: 0.35 / 1000000, // $0.35 per 1M input tokens
      output: 1.05 / 1000000, // $1.05 per 1M output tokens
    },
  };

  const modelCosts =
    costPerMillionTokens[model] || costPerMillionTokens["gpt-4o"];
  const inputCost = inputTokens * modelCosts.input;
  const outputCost = outputTokens * modelCosts.output;

  const estimatedCost = inputCost + outputCost;

  return estimatedCost.toFixed(2); // Return the cost rounded to two decimal places
};
