import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";

const CreateBlueArcFlow = () => {
  const [searchParams] = useSearchParams();
  const [businessName, setBusinessName] = useState(
    searchParams.get("businessName") || "",
  );
  const [businessLocation, setBusinessLocation] = useState(
    searchParams.get("businessLocation") || "",
  );
  const [flowStatus, setFlowStatus] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add("bg-gray-100", "dark:bg-gray-900");

    return () => {
      document.body.classList.remove("bg-gray-100", "dark:bg-gray-900");
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      businessName,
      businessLocation,
    };

    try {
      const response = await axios.post("/api/blue-arc", data);
      const newFlowId = response.data.id;
      navigate(`/flows/${newFlowId}`);
    } catch (error) {
      setFlowStatus("Failed to create flow: " + error.message);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex overflow-auto mt-8 mb-8 dark:bg-gray-900 dark:text-gray-300">
      <form onSubmit={handleSubmit} className="flex w-full">
        <div className="fixed inset-y-16 left-0 w-64 p-8 bg-gray-100 dark:bg-gray-900 dark:text-gray-300">
          <div className="space-y-4">{/* Left hand side bar */}</div>
        </div>
        <div className="flex-1 ml-64">
          <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-lg">
            <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-300 mb-6">
              Donobu
            </h2>
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="businessName"
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                >
                  Business Name
                </label>
                <input
                  type="text"
                  id="businessName"
                  name="businessName"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Pancake Solutions Company"
                />
              </div>
              <div>
                <label
                  htmlFor="businessLocation"
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                >
                  Business Address
                </label>
                <input
                  type="text"
                  id="businessLocation"
                  name="businessLocation"
                  value={businessLocation}
                  onChange={(e) => setBusinessLocation(e.target.value)}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="123 Main St, Durham, NC 227708"
                />
              </div>
              <div className="flex items-center justify-between mt-6">
                <button
                  type="submit"
                  className="z-0 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline dark:bg-blue-400 dark:hover:bg-blue-600"
                >
                  Submit
                </button>
              </div>
            </div>
            <div className="status text-sm text-gray-700 dark:text-gray-300 mt-2">
              {flowStatus}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateBlueArcFlow;
