import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import FlowItem from "./FlowItem";

const FlowsList = () => {
  const [flows, setFlows] = useState([]);
  const [filteredFlows, setFilteredFlows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get("search") || "";
    setSearchQuery(query);

    axios
      .get("/api/flows")
      .then((response) => {
        setFlows(response.data.flows);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch flows:", error);
        setError("Failed to fetch flows");
        setLoading(false);
      });
  }, [location.search]);

  useEffect(() => {
    const filtered = flows.filter((flow) => {
      const searchTarget = Object.entries(flow)
        .filter(([key]) => key !== "allowedTools")
        .map(([key, value]) => `${key}:${JSON.stringify(value)}`)
        .join(" ")
        .toLowerCase();

      return searchTarget.includes(searchQuery.toLowerCase());
    });
    setFilteredFlows(filtered);
  }, [searchQuery, flows]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFlowItemClick = (flowId) => {
    if (searchQuery) {
      // Update the URL with the current search query before navigating away.
      navigate(`?search=${encodeURIComponent(searchQuery)}`, { replace: true });
    } else {
      // Remove the search query from the URL if it's empty
      navigate(location.pathname, { replace: true });
    }
    // Now navigate to the flow details page
    navigate(`/flows/${flowId}`);
  };

  if (loading) {
    return <p>Loading...</p>;
  } else if (error) {
    return <p>{error}</p>;
  } else {
    return (
      <div className="max-w-4xl mx-auto mt-8">
        {flows.length > 1 && (
          <input
            type="text"
            placeholder="Search flows..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="mb-4 p-2 border border-gray-300 rounded"
          />
        )}
        {filteredFlows.map((flow) => (
          <div key={flow.id} onClick={() => handleFlowItemClick(flow.id)}>
            <FlowItem metadata={flow} />
          </div>
        ))}
      </div>
    );
  }
};

export default FlowsList;
