// In TruncatedText.js
import React, { useState } from "react";

const TruncatedText = ({ text, maxLength = 100, className = "" }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const toggleTruncation = () => setIsTruncated(!isTruncated);

  if (text.length <= maxLength) {
    return <p className={className}>{text}</p>;
  }

  const truncatedText = text.slice(0, maxLength) + "...";

  return (
    <p className={className}>
      {isTruncated ? truncatedText : text}
      <span
        className="text-blue-500 hover:underline cursor-pointer"
        onClick={toggleTruncation}
      >
        {isTruncated ? " (more)" : " (less)"}
      </span>
    </p>
  );
};

export default TruncatedText;
