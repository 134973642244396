import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "./Modal";
import ConfirmModal from "./ConfirmModal";
import { getDisplayText, truncateLink, estimateTokensCost } from "./utils";
import {
  getStatusBadge,
  getBrowserIcon,
  getDeviceIcon,
  getModeBadge,
} from "./badgeUtils";
import PlayIcon from "./svgIcons/PlayIcon";
import { format } from "date-fns";
import TruncatedText from "./TruncatedText";

const FlowMetadataItem = ({ metadata, toolCallsData }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [previewImageId, setPreviewImageId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const toolCallToGetPreviewImageFrom = toolCallsData.find(
      (item) => item.toolName === "navigateToWebpage",
    );
    // Try to source the preview image from the result of the first navigation.
    if (toolCallToGetPreviewImageFrom) {
      setPreviewImageId(toolCallToGetPreviewImageFrom.postCallImageId);
    } else if (0 < toolCallsData.length) {
      // If we are here, there was no navigation action taken, so just grab the first
      // image we see.
      setPreviewImageId(toolCallsData[0].postCallImageId);
    }
  }, [toolCallsData]);

  const toggleExpansion = () => setIsExpanded(!isExpanded);
  const toggleVideoModal = () => setIsVideoModalOpen(!isVideoModalOpen);
  const toggleConfirmModal = () => setIsConfirmModalOpen(!isConfirmModalOpen);

  const handleReRunFlow = async () => {
    try {
      const sourceRerunDataResponse = await axios.get(
        `/api/flows/${metadata.id}/rerun`,
      );
      const rerunMetadataResponse = await axios.post(
        `/api/flows`,
        sourceRerunDataResponse.data,
      );
      const newFlowId = rerunMetadataResponse.data.id;
      navigate(`/flows/${newFlowId}`);
    } catch (err) {
      console.error("Failed to re-run flow: " + err);
    }
  };

  const handleDeleteFlow = async () => {
    try {
      await axios.delete(`/api/flows/${metadata.id}`);
      const currentPath = window.location.pathname;
      // Check if the user is already on the individual flow's page.
      if (currentPath === `/api/flows/${metadata.id}`) {
        // Navigate back to the flows list
        navigate("/flows");
      } else {
        // Refresh the page to reflect the deletion
        window.location.reload();
      }
    } catch (err) {
      console.error("Failed to delete flow:", err);
    }
  };

  const handleNewFlow = async () => {
    try {
      const recentParamsResponse = await axios.get("/api/flows?limit=1");
      const recentParams = recentParamsResponse.data.flows[0];

      const data = {
        openAiKey: recentParams.openAiKey,
        targetWebsite: metadata.targetWebsite,
        overallObjective: metadata.overallObjective,
        contextHelpForObjective: metadata.contextHelpForObjective,
        deviceName: metadata.deviceName,
        maxIterations: metadata.maxIterations,
        allowedTools: metadata.allowedTools ? metadata.allowedTools : [],
      };

      navigate("/", { state: data });
    } catch (err) {
      console.error("Failed to load most recent run params:", err);
    }
  };

  const estimatedCost = estimateTokensCost(
    metadata.inputTokensUsed,
    metadata.completionTokensUsed,
    metadata.gptModel,
  );

  return (
    <div className="mb-4 pb-4 bg-gray-200 dark:bg-gray-600 dark:text-gray-300 bg-opacity-50 rounded-lg max-w-4xl mx-auto">
      <div className="grid grid-cols-4">
        <div className="col-span-3 pt-4 px-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4">
            <div className="cursor-pointer" onClick={toggleExpansion}>
              <h2 className="text-xl font-semibold">
                {getDisplayText(metadata.targetWebsite)}
              </h2>
              <p>{metadata.overallObjective}</p>
              <div className="flex justify-end space-x-2 mt-2">
                <div className="flex items-center -mr-1 tooltip">
                  {getDeviceIcon(metadata.deviceName)}
                  {getBrowserIcon(metadata.deviceName)}
                  <span className="tooltiptext">{metadata.deviceName}</span>
                </div>
                {getModeBadge(metadata.runMode)}
                {getStatusBadge(metadata.status.code)}
              </div>
            </div>
            {isExpanded && (
              <div>
                <div className="bg-gray-200 dark:bg-gray-600 rounded-b-lg mt-2 p-2">
                  {metadata.contextHelpForObjective && (
                    <>
                      <h3 className="expanded-element-label">Prompt</h3>
                      <div className="expanded-element-description-container">
                        <TruncatedText
                          text={metadata.contextHelpForObjective}
                          maxLength={500}
                          className="expanded-element-description-text"
                        />
                      </div>
                    </>
                  )}

                  <h3 className="expanded-element-label">Target Website</h3>
                  <div className="expanded-element-description-container">
                    <p className="expanded-element-description-text">
                      {truncateLink(metadata.targetWebsite)}
                    </p>
                  </div>

                  <h3 className="expanded-element-label">Iterations</h3>
                  <div className="expanded-element-description-container">
                    <p className="expanded-element-description-text">
                      {metadata.iterations}
                    </p>
                  </div>

                  {metadata.status.details && (
                    <>
                      <h3 className="expanded-element-label">Details</h3>
                      <div className="expanded-element-description-container">
                        <TruncatedText
                          text={metadata.status.details}
                          maxLength={100}
                          className="expanded-element-description-text"
                        />
                      </div>
                    </>
                  )}

                  {metadata.resultJsonSchema && (
                    <>
                      <h3 className="expanded-element-label">
                        Json Schema for Output
                      </h3>
                      <div className="expanded-element-description-container">
                        <pre className="bg-gray-900 text-emerald-300 p-4 rounded-md overflow-x-auto text-sm">
                          <code className="language-javascript">
                            {JSON.stringify(metadata.resultJsonSchema, null, 2)}
                          </code>
                        </pre>
                      </div>
                    </>
                  )}

                  {metadata.resultJsonSchema && metadata.result && (
                    <>
                      <h3 className="expanded-element-label">Json Output</h3>
                      <div className="expanded-element-description-container">
                        <pre className="bg-gray-900 text-emerald-300 p-4 rounded-md overflow-x-auto text-sm">
                          <code className="language-javascript">
                            {JSON.stringify(metadata.result, null, 2)}
                          </code>
                        </pre>
                      </div>
                    </>
                  )}

                  {metadata.startedAt && (
                    <>
                      <h3 className="expanded-element-label">Time</h3>
                      <div className="expanded-element-description-container">
                        <p className="expanded-element-description-text">
                          {metadata.startedAt
                            ? format(
                                new Date(metadata.startedAt),
                                "MMMM d, yyyy h:mm:ss a",
                              )
                            : ""}
                          {metadata.completedAt
                            ? " - " +
                              format(
                                new Date(metadata.completedAt),
                                "MMMM d, yyyy h:mm:ss a",
                              )
                            : ""}
                        </p>
                      </div>
                    </>
                  )}

                  <h3 className="expanded-element-label">Tokens Used</h3>
                  <div className="expanded-element-description-container">
                    <p className="expanded-element-description-text">
                      {metadata.inputTokensUsed} Input,{" "}
                      {metadata.completionTokensUsed} Output, ${estimatedCost}{" "}
                      estimated cost
                    </p>
                  </div>
                </div>
                <div className="flex justify-end space-x-2 mt-2">
                  <button
                    onClick={handleReRunFlow}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600"
                  >
                    Rerun
                  </button>
                  <button
                    onClick={handleNewFlow}
                    className="px-4 py-2 bg-green-500 text-white rounded-lg shadow hover:bg-green-600"
                  >
                    Recreate
                  </button>
                  <button
                    onClick={toggleConfirmModal}
                    className="px-4 py-2 bg-red-500 text-white rounded-lg shadow hover:bg-red-600"
                  >
                    Delete
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {previewImageId && (
          <div className="col-span-1 rounded-lg pt-4 px-4">
            <div className="cursor-pointer flex items-center justify-center relative">
              <img
                src={`/api/flows/${metadata.id}/images/${previewImageId}`}
                alt="Screenshot"
                className="w-full h-auto rounded-lg"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVideoModal();
                }}
              />
              <div
                className="absolute inset-0 flex items-center justify-center"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVideoModal();
                }}
              >
                <PlayIcon />
              </div>
            </div>
          </div>
        )}
      </div>

      {isVideoModalOpen && (
        <Modal isOpen={isVideoModalOpen} onClose={toggleVideoModal}>
          <video
            controls
            autoPlay
            className="max-w-full max-h-screen rounded-lg"
          >
            <source src={`/api/flows/${metadata.id}/video`} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </Modal>
      )}

      {isConfirmModalOpen && (
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={toggleConfirmModal}
          onConfirm={handleDeleteFlow}
        >
          <p>Are you sure you want to delete this flow?</p>
        </ConfirmModal>
      )}
    </div>
  );
};

export default FlowMetadataItem;
