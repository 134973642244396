import React, { useState } from "react";
import Modal from "./Modal";
import axios from "axios";
import { getStatusBadge } from "./badgeUtils";

// This component renders data relating to the status and run mode of the given flow.
const FlowModeItem = ({ metadata }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toolCallJson, setToolCallJson] = useState("");
  const [error, setError] = useState("");

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setToolCallJson(""); // Clear the input field after closing the modal
    setError(""); // Clear any errors
  };

  const handleSubmitToolCall = async () => {
    try {
      // Parse the JSON input to ensure it's valid
      const parsedToolCall = JSON.parse(toolCallJson);

      // Send the POST request
      await axios.post(`/api/flows/${metadata.id}/tool-calls`, parsedToolCall);

      // Close the modal upon successful submission
      handleCloseModal();
    } catch (err) {
      // Handle JSON parsing errors or API errors
      if (err instanceof SyntaxError) {
        setError("Invalid JSON format.");
      } else {
        console.error("Failed to submit tool call:", err);
        setError("Failed to submit tool call.");
      }
    }
  };

  return (
    <div className="flex justify-center mt-4">
      {metadata.status.code !== "RUNNING" ? (
        <div className="flex items-center justify-center bg-gray-100 dark:bg-gray-700 p-4 rounded-lg shadow">
          {getStatusBadge(metadata.status.code, false)}
        </div>
      ) : metadata.runMode === "AUTONOMOUS" ? (
        <button
          type="button"
          className="flex items-center justify-center bg-indigo-500 text-white px-4 py-2 rounded-lg shadow"
          disabled
        >
          <svg
            className="animate-spin h-5 w-5 mr-3 text-white"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
            ></path>
          </svg>
          Processing...
        </button>
      ) : metadata.runMode === "INSTRUCT" ? (
        <>
          <button
            className="flex items-center justify-center w-12 h-12 bg-green-500 text-white rounded-full shadow hover:bg-green-600"
            onClick={handleOpenModal}
          >
            <svg
              className="h-6 w-6 text-white"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4v16m8-8H4"
              />
            </svg>
          </button>
          {isModalOpen && (
            <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
              <div className="p-4">
                <h2 className="text-xl font-semibold mb-4">Submit Tool Call</h2>

                <textarea
                  value={toolCallJson}
                  onChange={(e) => setToolCallJson(e.target.value)}
                  rows={8}
                  className="w-full p-2 border rounded-lg bg-gray-100 dark:bg-gray-800 dark:text-gray-300"
                  placeholder="Enter your tool call JSON here"
                ></textarea>

                {error && <p className="text-red-500 mt-2">{error}</p>}

                <div className="flex justify-end mt-4 space-x-2">
                  <button
                    className="px-4 py-2 bg-gray-500 text-white rounded-lg shadow hover:bg-gray-600"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600"
                    onClick={handleSubmitToolCall}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Modal>
          )}
        </>
      ) : null}
    </div>
  );
};

export default FlowModeItem;
