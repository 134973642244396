// src/PlayIcon.js
import React from "react";

const PlayIcon = () => (
  <svg
    className="w-12 h-12 text-white bg-black bg-opacity-75 rounded-lg p-2"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="4" ry="4" fill="black" />
    <path d="M8 5v14l11-7-11-7z" fill="white" />
  </svg>
);

export default PlayIcon;
